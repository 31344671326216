import { render, staticRenderFns } from "./statistics-details.vue?vue&type=template&id=53c70398&scoped=true"
import script from "./statistics-details.vue?vue&type=script&lang=js"
export * from "./statistics-details.vue?vue&type=script&lang=js"
import style0 from "./statistics-details.vue?vue&type=style&index=0&id=53c70398&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53c70398",
  null
  
)

export default component.exports