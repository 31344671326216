<template>
  <div>
    <el-form ref="form" :model="form" label-width="90px" style="display: flex">
      <div class="mb-3">
        <el-row type="flex" justify="space-between">
          <div style="width: 75%">
            <el-row class="Row-Input" :class="{ 'show-row': showRow }">
              <!-- 平台 -->
              <el-col :span="6">
                <el-form-item :label="$t('title.platform')">
                  <el-select v-model="form.platform" clearable filterable :placeholder="$t('page.selectPlaceholder')">
                    <el-option
                      v-for="item in platformList"
                      :key="item.id"
                      :label="item.platformCode"
                      :value="item.platformCode"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 名称 -->
              <el-col :span="7">
                <el-form-item :label="$t('title.username')">
                  <el-input v-model="form.userName" disabled :placeholder="$t('page.inputPlaceholder')" />
                </el-form-item>
              </el-col>
              <!-- 标签 -->
              <el-col :span="7">
                <el-form-item :label="$t('title.ProblemMentioned')">
                  <el-cascader v-model="form.labelsId" :options="tagData" clearable :props="props">
                    <template slot-scope="{ data }">
                      <span>{{ data.labelName }}</span>
                    </template>
                  </el-cascader>
                </el-form-item>
              </el-col>
              <!-- BU组 -->
              <el-col :span="7">
                <el-form-item :label="$t('title.TicketStatus')">
                  <el-select v-model="form.workStatus" clearable filterable :placeholder="$t('page.selectPlaceholder')">
                    <el-option :label="$t('title.Unfinished')" value="0">{{ $t('title.Unfinished') }}</el-option>
                    <el-option :label="$t('title.finished')" value="99">{{ $t('title.finished') }}</el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 时间 -->
              <el-col :span="8">
                <el-form-item :label="$t('title.selectionperiod')">
                  <el-date-picker
                    v-model="form.date"
                    type="datetimerange"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    range-separator="~"
                    :start-placeholder="$t('title.starttime')"
                    :end-placeholder="$t('title.endtime')"
                    :unlink-panels="true"
                    :default-time="['00:00:00', '23:59:59']"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <el-row style="width: 25%">
            <el-col class="row-center">
              <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
              <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
              <el-button @click="handleExport">{{ $t('page.export') }}</el-button>
              <el-button type="text" @click="showRow = !showRow">{{ showRow ? $t("page.hide") : $t("page.expand") }}</el-button>
            </el-col>
          </el-row>
        </el-row>
      </div>
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="TableLoading"
      class="mb-3"
      :data="tableDatas"
      :header-cell-style="{background:'#fafafa',color:'#606266'}"
      max-height="500px"
    >
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="80"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="workNumber"
        :label="$t('title.Ticketno')"
        align="center"
        sortable
      />

      <el-table-column
        prop="workStatusName"
        :label="$t('title.TicketStatus')"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          <p v-if="scope.row.workStatus == 0"> {{ $t("title.Unfinished") }}</p>
          <p v-if="scope.row.workStatus == 99"> {{ $t("title.finished") }}</p>
        </template>
      </el-table-column>

      <el-table-column
        prop="workLabels"
        :label="$t('title.Problemmentioned')"
        align="center"
        sortable
      />
      <!-- <el-table-column sortable align="center" prop="workLabels" label="工单标签">
        <template slot-scope="scope">
          <el-popover placement="top-start" width="300" trigger="hover" :disabled="scope.row.workLabels.length <= 100">
            <div>{{ scope.row.workLabels }}</div>
            <span v-if="scope.row.workLabels.length <= 100" slot="reference">{{ scope.row.workLabels }}</span>
            <span v-if="scope.row.workLabels.length > 100" slot="reference">{{ scope.row.workLabels.substr(0, 150) + "..." }}</span>
          </el-popover>
        </template>
      </el-table-column> -->
      <el-table-column
        prop="replyCount"
        :label="$t('title.Sentmessagestime')"
        align="center"
        sortable
      />
      <el-table-column
        prop="responseTime"
        :label="$t('tips.ResponseTime')"
        align="center"
        sortable
      />
      <el-table-column
        prop="replyTime"
        :label="$t('title.Respondingtimes')"
        align="center"
        sortable
      />
      <el-table-column
        prop="circulationCount"
        :label="$t('title.Transferringtime')"
        align="center"
        sortable
      />
    </el-table>
    <el-pagination
      :current-page="pager.current"
      :page-sizes="[10, 50, 100, 200, 300]"
      :page-size="pager.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pager.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
// import oms_base from '@/api/a-base-host'
import mixin from '@/mixin/oms-mixin'
import { queryTerraceList } from '@/api/omsorder'
import { pagePersonCount, allBaseBu, messagesGetLabel } from '@/api/service-management'
export default {
  mixins: [mixin],
  data() {
    return {
      pager: {
        current: 1,
        size: 10,
        total: 0
      },
      showRow: false,
      TableLoading: false,
      tableDatas: [],
      form: {
        labelsId: '',
        date: [],
        workStatus: '',
        userName: '',
        userId: '',
        platform: ''
      },
      buOption: [],
      tagData: [],
      props: { checkStrictly: true, value: 'id', label: 'labelName', children: 'childNodes' },
      labelsId: '',
      platformList: []

    }
  },

  computed: {
    queryParameter() {
      this.getLabel()
      const [startTime = '', endTime = ''] = this.form.date || []
      const { userName, workStatus, platform } = this.form
      return Object.assign({}, { userName, workStatus, platform }, this.pager, { labelIds: this.labelsId, startTime, endTime, userId: this.$route.query.userId })
    },
    exportUrl() {
      this.getLabel()
      const obj = {}
      const { bu, userName, workStatus, platform } = this.form
      const [startTime, endTime] = this.form.date || []
      const OBJ = Object.assign({}, { bu, userName, workStatus, platform, userId: this.$route.query.userId, labelIds: this.labelsId }, { startTime, endTime })
      for (var key in OBJ) {
        OBJ[key] ? obj[key] = OBJ[key] : ''
      }
      console.log(obj)
      let url = ''
      for (var i in obj) {
        url = `${i}=${obj[i]}` + '&' + url
      }
      url = url.substr(0, url.length - 1)
      return process.env.VUE_APP_ERP_NEW_API + 'mos-web-core/Customer/exportMessagePersonCount?' + url
    }
  },

  mounted() {
    const { userId, userName } = this.$route.query
    this.form.userName = userName
    this.form.userId = userId
    this._pagePersonCount()
    this._allBaseBu()
    this._messagesGetLabel()
    this._queryTerraceList()
  },
  methods: {
    // 获取平台消息
    async _queryTerraceList() {
      const { datas } = await queryTerraceList()
      this.platformList = datas
      this.platformList = this.platformList.filter((item) => {
        return item.platformCode === 'amazon' ||
        item.platformCode === 'ebay' ||
        item.platformCode === 'walmart' ||
        item.platformCode === 'Shopify' ||
        item.platformCode === 'standwebsite' ||
        // sb正式测试不一样
        item.platformCode === 'Tik Tok' ||
        item.platformCode === 'TikTok'
      })
    },
    getLabel() {
      this.labelsId = this.form.labelsId
      this.labelsId = this.labelsId && this.labelsId[this.labelsId.length - 1]
    },
    // 导出文件
    handleExport() {
      window.open(this.exportUrl, '_blank')
    },
    async _messagesGetLabel() {
      const { datas } = await messagesGetLabel(true)
      this.tagData = datas
    },
    // 点击查询获取信息
    queryClick() {
      this.pager.current = 1
      this._pagePersonCount(this.queryParameter)
    },
    // 点击重置清空文本框信息
    resetClick() {
      this.form = {}
      this.form.userName = this.$route.query.userName
      this._pagePersonCount(1)
    },
    // 点击展开控制文本框的显示隐藏
    showRowClick() {
      this.showRow = !this.showRow
    },
    // 查询所有BU
    async _allBaseBu() {
      const { datas } = await allBaseBu()
      this.buOption = datas
    },
    // 默认查询
    async _pagePersonCount(params) {
      try {
        this.TableLoading = true
        const { datas } = await pagePersonCount(this.queryParameter)
        this.tableDatas = datas.records
        this.pager = datas.pager
      } finally {
        this.TableLoading = false
      }
    },
    handleSizeChange(val) {
      this.pager.size = val
      this._pagePersonCount(this.queryParameter)
    },
    handleCurrentChange(val) {
      this.pager.current = val
      this._pagePersonCount(this.queryParameter)
    }

  }
}
</script>

<style scoped lang="scss">
.mark,
.count {
  color: red;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

/deep/.el-tabs__header {
  margin: 0 !important;
}
.Row-Input {
  height: 38px;
  overflow: hidden;
}
.show-row {
  height: auto;
}
</style>
